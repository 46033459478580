import React, { useState } from 'react'

import Logo from '../../Assets/img/logo.png'
import LogoWhite from '../../Assets/img/logo-white.png'
import HomeImg from '../../Assets/img/home.png'
import AboutImg from '../../Assets/img/about.png'
import VisiImg from '../../Assets/img/visi.png'
import M1 from '../../Assets/img/m1.png'
import M2 from '../../Assets/img/m2.png'
import M3 from '../../Assets/img/m3.png'
import Misi from '../../Assets/img/misi.png'
import Padi from '../../Assets/img/padi.png'
import Pupuk from '../../Assets/img/pupuk.png'
import Obat from '../../Assets/img/obat.png'
import User1 from '../../Assets/img/user1.jpeg'
import User2 from '../../Assets/img/user2.jpeg'
import Instagram from '../../Assets/img/instagram.png'
import Whatsapp from '../../Assets/img/whatsapp.png'
import Linkedin from '../../Assets/img/linkedin.png'
import Twitter from '../../Assets/img/twitter.png'
import Facebook from '../../Assets/img/facebook.png'

function Index() {

    const [active , setActive] = useState(false)
    const [menu , setMenu] = useState(1)

  return (
    <div className='wrap'>
        <div id="home" className='home'>
            <div className='home-content'>
                <div className='nav'>
                    <div className='nav-header'>
                        <img src={Logo} alt="" />

                        <div className='btn-burger' onClick={() => setActive(true)}>
                            <div className={`burger ${active ? 'isActive' : ''}`}  />
                        </div>
                    </div>
                    
                    <div className={`nav-link ${active ? 'isActive' : ''}`}>
                        <button onClick={() => setActive(false)}>X</button>
                        <a onClick={() => {
                            setMenu(1)
                            setActive(false)
                        }} className={`nav-link-items ${menu === 1 ? 'isActive' : ''}`} href="#home">Beranda</a>
                        <a onClick={() => {
                            setMenu(2)
                            setActive(false)
                        }} className={`nav-link-items ${menu === 2 ? 'isActive' : ''}`} href="#about">Tentang</a>
                        <a onClick={() => {
                            setMenu(3)
                            setActive(false)
                        }} className={`nav-link-items ${menu === 3 ? 'isActive' : ''}`} href="#product">Produk</a>
                        <a onClick={() => {
                            setMenu(4)
                            setActive(false)
                        }} className={`nav-link-items ${menu === 4 ? 'isActive' : ''}`} href="#kontak">Kontak</a>
                    </div>

                </div>
                <div className='home-content-info'>
                    <h2 className='home-content-info-title'>
                        Sahabat Para Petani, Selamanya.
                    </h2>
                    <a onClick={() => {
                            setMenu(2)
                            setActive(false)
                        }} className={`nav-link-items ${menu === 2 ? 'isActive' : ''}`} href="#about">
                            <button className='home-content-info-btn'>
                                Pelajari
                            </button>
                    </a>
                </div>
            </div>
            <div className='home-img'>
                <img src={HomeImg} alt="" />
            </div>
        </div>
        <div id="about" style={{
            backgroundImage : `url('${AboutImg}')`
        }} className='about'>
            <h4 className='about-title'>
                Tentang Kami
            </h4>
            <p className='about-subtitle'>
                Kami adalah perusahaan penyedia bibit, pupuk, dan obat-obatan yang terbaik bagi mitra petani sejak 2021.
            </p>
            <div className='about-overlay' />
        </div>
        <div className='visi'>
            <div className='visi-img'>
                <img src={VisiImg} alt="" />
            </div>
            <div className='visi-content'>
                <h4 className='visi-content-title'>Visi Kami</h4>
                <p className='visi-content-text'>
                    Mewujudkan sektor pertanian Indonesia agar bertumbuh kembang secara positif melalui penyediaan produk yang unggul dan terpercaya.
                </p>
            </div>
        </div>
        <div className='misi'>
            <div className='misi-content'>
                <h2 className='misi-content-title'>Misi Kami</h2>
                <div className='misi-content-info'>
                    <div className='misi-content-info-card'>
                        <div className='misi-content-info-card-number'>
                            <img src={M1} alt="" />
                        </div>
                        <div className='misi-content-info-card-text'>
                            <p className='misi-text'>
                                Menyediakan serta menyalurkan produk pertanian buatan kami seperti bibit, pupuk, dan obat-obatan kepada seluruh mitra dengan kualitas terbaik.
                            </p>
                        </div>
                    </div>
                    <div className='misi-content-info-card'>
                        <div className='misi-content-info-card-number'>
                            <img src={M2} alt="" />
                        </div>
                        <div className='misi-content-info-card-text'>
                            <p className='misi-text'>
                                Kepuasan para mitra merupakan prioritas utama kami, seluruh layanan seperti quality control barang, harga terbaik, hingga waktu pengiriman yang tepat waktu selalu menjadi komitmen kami.
                            </p>
                        </div>
                    </div>
                    <div className='misi-content-info-card'>
                        <div className='misi-content-info-card-number'>
                            <img src={M3} alt="" />
                        </div>
                        <div className='misi-content-info-card-text'>
                            <p className='misi-text'>
                                Menyediakan serta menyalurkan produk pertanian buatan kami seperti bibit, pupuk, dan obat-obatan kepada seluruh mitra dengan kualitas terbaik.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='misi-img'>
                <img src={Misi} alt="" />
            </div>
        </div>
        <div id="product" className='product'>
            <h2 className='product-title'>Produk Kami</h2>
            <div className='product-wrap'>
                <div className='product-card'>
                    <img src={Padi} alt="" />
                    <p className='product-card-title'>Bibit Jagung & Padi</p>
                    <p className='product-card-text'>Kami menyediakan bibit jagung dan padi berkualitas tinggi</p>
                </div>
                <div className='product-card'>
                    <img src={Pupuk} alt="" />
                    <p className='product-card-title'>Pupuk Organik & Anorganik</p>
                    <p className='product-card-text'>Kami juga siap mendistribusikan pupuk terbaik bagi para mitra kami.</p>
                </div>
                <div className='product-card'>
                    <img src={Obat} alt="" />
                    <p className='product-card-title'>Obat-Obatan Tanaman</p>
                    <p className='product-card-text'>Kami juga menyediakan berbagai macam obat seperti fungisida, herbisida, rodentisida, dst.</p>
                </div>
            </div>
        </div>
        <div className='team'>
            <h2 className='team-title'>Team Kami</h2>
            <div className='team-wrap'>
                <div className='team-card'>
                    <img className='team-card-img' src={User1} alt="" />
                    <p className='team-card-name'>Tri Saptami</p>
                    <p className='team-card-title'>Komisaris</p>
                </div>
                <div className='team-card'>
                    <img className='team-card-img' src={User2} alt="" />
                    <p className='team-card-name'>Mohammad Sjahrial</p>
                    <p className='team-card-title'>Direktur</p>
                </div>
            </div>
        </div>
        <div className='join'>
            <h2 className='join-title'>Ayo Tumbuh Bersama Kami</h2>
            <button className='join-btn'>Cari Tau Sekarang</button>
            <div className='join-overlay' />
        </div>
        <div id="kontak" className='footer'>
            <img className='footer-logo' src={LogoWhite} alt="" />
            <div className='footer-wrap'>
                <div className='footer-card'>
                    <p className='footer-title'>PT Segar Selalu  Selamanya</p>
                    <p className='footer-location'>
                        Jl. TB Simatupang No. 18C, Cilandak Barat, Cilandak, Jakarta Selatan
                    </p>
                </div>

                <div className='footer-card2'>
                    <p className='footer-title'>Kontak</p>
                    <div className='footer-card-wrap'>
                        <div className='footer-card-wrap-left'>
                            <p className='footer-card-wrap-text'>Phone</p>
                            <p className='footer-card-wrap-text'>Email</p>
                            <p className='footer-card-wrap-text'>Social Media</p>
                        </div>

                        <div className='footer-card-wrap-right'>
                            <p className='footer-card-wrap-text'>+62 21-29222999</p>
                            <p className='footer-card-wrap-text'>sjahrial@segarselalu.com</p>
                            <p className='footer-card-wrap-text'>
                                <a><img src={Facebook} /></a>
                                <a><img src={Twitter} /></a>
                                <a><img src={Linkedin} /></a>
                                <a><img src={Instagram} /></a>
                                <a><img src={Whatsapp} /></a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='footer-card3'>
                    <p className='footer-title'>Menu</p>
                    <a href='#home'>Beranda</a>
                    <a href='#about'>Tentang</a>
                    <a href='#product'>Produk</a>
                    <a href='#kontak'>Kontak</a>
                </div>
            </div>
            <div className='footer-rights'>
                <p> &copy; 2022 Segar Selalu Selamanya. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  )
}

export default Index