import Apps from './Pages/Home'
import './Style/index.scss'

function App() {
  return (
    <Apps />
  );
}

export default App;
